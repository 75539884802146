body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	min-height: 100vh;
}

.container {
	max-width: 1140px;
}

.mt-0 {
	margin-top: 0;
}

.mt-4 {
	margin-top: 4rem;
}

.mt-3 {
	margin-top: 3rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.my-4 {
	margin: 4rem 0;
}

.show-on-large {
    display: none;
}

.flex-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'ValtechNeue', sans-serif;
    font-weight: 300;
}

h2 {
	font-size: 2.2rem;
}

a {
	color: $black;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

label {
    font-size: 16px;
}

.btn {
    display: block;
    margin: 0 0 30px;
    padding: 0 1rem;
    width: 100%;
    height: 50px;
    border: 2px solid $black;
    color: $black;
    font-size: 18px;
    line-height: 48px;
    text-align: center;
    text-decoration: none;
    background: #fff;
    transition: all 150ms ease;
    cursor: pointer;
    box-shadow: none;
    text-transform: none;

    &:focus {
        color: $black;
        background: #fff;
    }
    
    &:hover {
        color: #fff;
        background: $black;
    }
}

.blue-link {
    color: $ocean;
}

.btn.btn-primary {
	width: 100%;
	height: auto;
	padding: 14px;
	border: 2px solid $black;
	background: #fff;
	color: $black;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0;
}

.btn.btn-primary:hover {
	color: #fff;
	background: $black;
}

.btn.btn-secondary {
	color: #fff;
	background: $black;

    &.btn-small {
        max-width: 220px;
    }

    &:hover {
        background: $white;
        color: $black;
    }
}

.flex-row .btn {
	width: 48%;
}

#root,
.app-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}


.input-group {
    margin: 0 0 24px;

    label {
        display: block;
        margin: 0;
    }

    textarea {
        margin: 0;
        padding: 12px 15px;
        color: $black;
        font-size: 16px;
        line-height: 20px;
        border: 1px solid $black;
        min-height: 100px;

        &::placeholder {
            color: $shade-gray;
        }

        &:focus {
            border: 1px solid $black!important;
            box-shadow: none!important;
            
            &::placeholder {
                color: transparent;
            }
        }

        @media (max-width: 768px) {
            &#subject {
                &::placeholder {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .materialize-textarea.invalid {
        border: 1px solid #F44336!important;
        box-shadow: none!important;
    }
    .materialize-textarea.valid {
        border: 1px solid #4CAF50!important;
        box-shadow: none!important;
    }
    

    &--subject {
        textarea {
            min-height: 40px;
        }
    }
}

.preloader-page {
    max-width: 800px;
    min-height: 70vh;
    display: flex;
    align-items: center;
}


@media (max-width: 768px) {
    .container {
        width: calc(100% - 40px);
    }
}


.valid-text {
    color: #4CAF50;
}

// fix for mobile horizontal scrollbar / this is materialize autoheight for textarea inputs
.hiddendiv.common {
    max-width: 100%;
}

.remove-duplicates {
    ul li {
        width: 100%;
        border-top: 1px solid gray;
    }
}

.red-text {
	color: red;
}
