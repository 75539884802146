
/* modal  */
.modal {
	border: none;
	border-radius: 0;
	background: none;
	overflow: hidden;
    height: 100%;
    width: 100%;
    max-width: 800px;
    max-height: none;
    top: 0!important;
    transform: none !important;
    box-shadow: none;

    .modal-content {
        padding: 0;
        height: 100%;
        display: flex!important;
        align-items: center;
        flex-wrap: wrap;
        // top: 49%!important;
        // transform: translate3d(0, -50%, 0) !important;

        &--wrapper {
            width: 100%;
            border: 2px solid $forest;
            border-radius: 0;
		    background: $off-white;
            overflow: hidden;
            height: auto;
            max-height: none;
            position: relative;
            align-items: center;
        }

        &--wrapper2 {  
            width: 100%;
            height: 100%;
            padding: 65px 60px 24px;
            max-height: 85vh;
            overflow-y: auto;
        }
    }

    .modal-footer {
        background-color: $white;
    }
    
    .modal-close {
        height: 50px;
        width: 50px;
        line-height: 60px;
        position: absolute;
        top: 0;
        right: 17px;
        background: transparent;
        border: none;

        &:focus,
        &:hover,
        i {
            color: $black;
            background: transparent;
        }
        
        &:hover i {
            color: #000;
        }
    }
    
    &--request .modal-content--wrapper{ 
	    border: 2px solid $ocean;
    }

    

    @media (max-width: 768px) {
        .modal-content {
            top: 0!important;
            left: 0 !important;

    
            &--wrapper {
                width: 100%;
                height: 100%;
                max-height: none;
            }

            &--wrapper2 {
                max-height: none;
                padding: 35px 20px 24px;
            }
        }
    }
}