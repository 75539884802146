@import './fonts.scss';
@import './variables.scss';
@import './helpers.scss';
@import './modal.scss';

body {
	background: $off-white;
	font-family: 'Soehne', sans-serif;
}

main {
	flex: 1 0 auto;
}

select {
	border: 1px solid black;
	background: $off-white;
}

.nav-wrapper {
	background: $off-white;
}

nav ul li {
	&:last-child a{
		padding-right: 0;
	}
	a {
		color: $black;
		font-size: 16px;
		text-shadow: none;

		&:hover {
			background: none;

			span.nav-link-text {
				text-decoration: underline;
			}
		}
	}
}

.sidenav {
	height: 100%;
	padding: 2rem 0;
	background: $off-white;

	&.open {
		transform: translateX(0);
		display: block !important;
	}

	li>a {
		font-weight: 300;
		letter-spacing: 0.05rem;

	}


	@media (max-width: 992px) {
		.logout-link {
			position: absolute;
			bottom: 2rem;
			left: 1rem;
		}
	}
}

.sidenav-overlay.active {
	display: block;
	opacity: 1;
}


header {
	a {
		text-decoration: none;
	}

	.sidenav-trigger {
		width: 50px;
		height: 53px;
		margin: 3px -10px 0 0;
		border: none;
		color: $white;
		cursor: pointer;
		font-size: 0;
		line-height: 0;
		background: url('../img/hamburger.svg') center no-repeat;
	}

	.brand-logo {
		width: 140px;
		height: 26px;

		img {
			width: 100%;
			height: auto;
		}

		&.left {
			left: auto;
		}
	}
}

.alert {
	padding: 0.5rem 1rem;
	border-radius: 10px;
	text-align: center;
	margin-bottom: 2.5rem;
	background: rgba(255, 0, 0, 0.3);
	color: red;
}

.page-footer {
	padding-top: 0;

	
	.footer-copyright {
		min-height: 70px;
		line-height: 20px;

	}

	a {
		color: $white;
	}
}

.received-counter {
	margin-left: 5px;
	color: $black;
	font-size: 18px;
	line-height: 14px;
}

.received-counter--feedbacks {
	color: $black;
}

.input-group,
.input-field {
	label {
		color: #000;
	}
	input:focus + label {
		color: #000;
	}
	input {
		box-shadow: none !important;
		border-bottom: 1px solid #000 !important;

		&:focus {
			border-bottom: 1px solid #000 !important;
			background: transparent!important;
		}

		&.valid {
			border-bottom: 1px solid #4caf50 !important;
			background: transparent!important;
		}
	}
	.prefix.active {
		color: #000;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* personal */

.section-headline {
	margin: 0 0 23px;
	display: flex;
	justify-content: space-between;
}

.section-headline--title {
	margin: 0;
	font-size: 26px;
}

.section-headline a {
	font-size: 18px;
}


label,
small {
	color: $shade-gray !important;
}

.select-wrapper input.select-dropdown {
	margin: 0;
}

.input-group,
.input-field {
	margin: 0 0 2rem;

	&.invalid .helper-text:before {
		content: attr(data-error);
		color: #f44336;
	}
}
.check-field-group {
	margin: 0 0 2rem -20px;

	.check-field {
		margin: 0 0 6px 20px;
		display: inline-block;
		text-transform: none;

		span {
			height: 26px;
			color: $black;
			font-size: 16px;
		}
	
		[type="checkbox"]:checked+span:not(.lever):before {
			border-right-color: $ocean;
			border-bottom-color: $ocean;
		}
		[type="checkbox"]:not(:checked):disabled+span:not(.lever):before {
			opacity: 0.5;
		}
	}
}

.rating-field {
	margin: 0 0 34px;

	.helper-text {
		margin: 30px 0 -5px;
		display: block;
		color: red;
	}
}
.rating-list {
	display: flex;
	align-items: flex-start;
	margin: 14px 0 0 -15px;

	li {
		position: relative;
		width: 70px;
		text-align: center;
		font-size: 12px;
		line-height: 1.2;

		&.active .rating-hint-text {
			opacity: 1;
			visibility: visible;
			transition: opacity 200ms ease, visibility 200ms;
		}
	}
	
	img {
		margin: 0 15px 4px;
		display: block;
		cursor: pointer;
		border-radius: 50%;
	
		&:focus {
			outline: none;
		}
	}
	
	.rating-hint-text {
		width: 100%;
		position: absolute;
		left: 0;
		top: 100%;
		opacity: 0;
		visibility: hidden;
		transition: opacity 200ms ease;
	}

    @media (max-width: 768px) {
		li {
			width: 60px;
		}
		img {
			margin: 0 10px 4px;
		}
    }
}


.dropdown-content li > a,
.dropdown-content li > span {
	color: #000;
}

/* feedback_card */
.feedback_card {
	position: relative;
	margin-bottom: 30px;
	cursor: pointer;

	&:hover .feedback_card-wrapper{
		border-color: #000;
	}

	&-wrapper {
		padding: 25px 15px 20px 24px;
		border: 2px solid $forest;
		transition: border-color 200ms ease;
	}
	
	&-name {
		margin: 0 0 1.4rem;
		color: #000;
		font-size: 16px;
		line-height: 19px;
		font-weight: 400;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&-rate {
		margin: 0 0 1.5rem;
		display: flex;
		align-items: center;

		&--text {
			margin-left: 1rem;
		}
	}

	&-text {
		margin: 0 0 1.4rem;

		p {
			height: 57px;
			overflow: hidden;
		}
	}
	
	&-date,
	&-subtitle {
		margin: 0 0 6px;
		color: rgba(0, 0, 0, 0.5);
		font-size: 14px;
		line-height: 18px;
	}

	&-date {
		margin: 0;
	}

	&-button {
		@extend .btn;

		margin: -10px 0 30px;
	}

	&-new--label {
		position: absolute;
		bottom: 0;
		right: 35px;
		height: 24px;
		padding: 9px 30px 0 6px;
		color: #000;
		font-size: 18px;
		line-height: 1;
		font-weight: bold;
		background: $moss;
	}

	p {
		margin: 0;
		color: #000;
		font-size: 16px;
		line-height: 19px;
		word-break: break-word;
	}

	// feedback_card-request
	&-request {
		height: 130px;
		padding: 25px 15px 15px 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border: 2px solid $ocean;
		cursor: default;

		.feedback_card {
			&-name {
				// height: 38px;
				margin: 0;
				white-space: normal;
			}
		}
	}
}

/* end feedback_card */

.received-feedbacks-couter {
	color: blue;
}



/* welcome-block */

.welcome-block {
	width: 100%;
	position: relative;
	overflow:hidden;
	color: $white;
	margin: 0 0 2rem;
	padding: 0 0 4rem;
	background: $off-white;
	
	&--wrap {
		position: relative;
		max-width: 1140px;
		width: 100%;
		margin: 0 auto;
		height: 440px;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		padding: 2.5rem 5rem;
		background: #000 url("./../img/welcome_img.jpg") top center no-repeat;
	}

	h2 {
		margin: 0 0 2rem;
		font-size: 36px;
		line-height: 43px;
		word-wrap: break-word;
	}

	&--content {
		display: flex;
		justify-content: space-between;
		

		@media (max-width:768px) {
			flex-wrap: wrap;
		}
	}

	&--button {
		width: 48.5%;
		padding: 49px 15px;
		/* display: block; */
		display: flex;
		align-items: center;
		justify-content: center;
		border: 3px solid $white;
		color: $white;
		font-size: 28px;
		line-height: 1;
		text-align: center;
		text-decoration: none;
		background: none;
		cursor: pointer;

		@media (max-width:768px) {
			width: 100%;
			padding: 29px 15px;
			margin: 0 0 20px;
			font-size: 18px;
		}
	}
	@media (max-width:768px) {
		padding: 0 0 1.8rem;

		&--wrap {
			padding: 1.5rem 2.5rem 1rem;

			&:after {
				display: none;
			}
		}
	}
}


.feedbacks-page {
	margin: 2rem 0 0;
}

.progress {
	border: 1px solid #000;
	height: 6px;
	background: $white;

	&-global {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.7);
		z-index: 10000;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		font-size: 28px;

		.progress {
			max-width: 50%;
			margin: 20px auto;
		}
	}
}

.progress .indeterminate {
	background: $black;
}

.personal {
	&-section {
		padding: 40px 0;
		margin: 0 0 40px;
		border-bottom: 3px solid $ocean;
	}

	&_navigation {
		display: flex;
		margin: 0 0 45px;

		li {
			margin-right: 34px;
			font-size: 24px;
			line-height: 29px;
			font-family: 'ValtechNeue', sans-serif;
			font-weight: 300;

			&:last-child {margin:0;}

			&.active a {
				color: $ocean;
				text-decoration: underline;
			}
		}

		a {
			color: #000;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.col {
		width: 100% !important;
	}

	.feedback {
		&_card {
			margin: -1px 0 0;
			padding: 11px 22px 14px 24px;
			border: 1px solid $forest;
			// border-top: 0;
			transition: box-shadow 150ms ease;

			&-wrapper {
				border: 0;
				padding: 0;
				display: flex;
				align-items: center;
			}

			&-name {
				margin: 0;
				min-width: 240px;
				max-width: 240px;
				font-weight: 400;
			}

			&-text {
				flex: 1;
				margin: 0;

				p {
					margin: 0;
					height: auto;
					font-size: 16px;
					line-height: 19px;
				}
			}

			&-rate {
				padding: 0 11px;
				margin: 0;

				img {
					width: 24px;
				}

				&--text {
					display: none;
				}
			}

			&-subtitle {
				display: none;
			}
			
			&.new-received-feedback {
				background: $white;

				.feedback_card {

					&-name {
						font-weight: bold;
					}

					&-new--label {
						display: none;
					}
				}
			}
			
			// feedback_card-request
			&-button {
				display: none;
			}

			&-request {
				height: auto;
				padding: 11px 22px 14px 24px;
				flex-direction: row;
				align-items: center;
				border: 1px solid $ocean;
				// border-top: 0;
				cursor: pointer;

				.feedback_card {
					&-name {
						height: auto;
						white-space: normal;
						font-weight: bold;
					}

					&-text {
						flex: 1;
						display: block;
					}
				}
			}

			&:hover {
				z-index: 1;
				box-shadow:  0 0 3px 1px rgba(0,0,0,0.3);
			}
		}
	}
	
	.tabs {
		height: 60px;
		margin: 2.5rem 0 2rem;
		display: flex;
		justify-content: space-between;
	}

	.tab {
		width: 48%;
		height: 60px;
		display: block;
		a {
			font-weight: bold;
			line-height: 58px;
			text-decoration: none;

			&:focus,
			&:hover,
			& {
				color: #000;
				border: 2px solid #000;
				border-left: 0;
				background: $white;
			}

			&.active:focus,
			&.active {
				color: $white;
				background: #000;
			}
		}
		&:first-child a {
			border-left: 2px solid #000;
		}
	}

	&-btn {
		max-width: 360px; 
		margin: 0 auto 50px;
	}

	.empty-list {
		padding-top: 200px;
		max-width: 350px;
		margin: 11rem auto 0;
		float: none;
		text-align: center;
		background: url('../img/rating_icons/3.svg') top center no-repeat;
		background-size: 160px;
		font-size: 16px;
		line-height: 19px;

		&--title{ 
			display: block;
			margin: 0 0 13px;
			font-size: 24px;
			line-height: 29px;
			font-weight: bold;
		}

	}
		
	@media (max-width: 768px) {
		

		.empty-list {
			padding-top: 150px;
			margin: 3rem auto 0;
			background-size: 120px;
		}

		.personal_navigation {
			margin: 0 0 25px;
			display: block;

			li {
				margin: 0 0 10px;
				font-size: 24px;
				line-height: 24px;
			}
		}

		.feedback_card {
			&-wrapper {
				justify-content: space-between;
			}

			&-rate {
				margin-right: auto;
			}

			&-text {
				display: none;
			}

			&-request .feedback_card {
				flex-wrap: wrap;

				&-text {
					display: none;
				}
				&-name {
					min-width: auto;
					padding: 0;
				}
			}
		}

		.feedbacks_list,
		.requests_list {
			margin: 0 -20px;
			padding-top: 1px;
			width: auto !important;
			overflow: hidden;
		}
	}
	@media (max-width: 530px) {
		.feedback_card {
			padding: 16px 20px 15px 20px;
			border-left: 0;
			border-right: 0;

			&-wrapper {
				flex-wrap: wrap;
			}

			&-date {
				position: absolute;
				right: 20px;
				top: 19px;
				width: 62px;
				font-size: 12px;
				white-space: nowrap;
				overflow: hidden;
			}

			&-rate {
				padding-left: 0;
			}

			&-text {
				width: calc(100% - 35px);
				padding: 5px 10px 5px 0;
				display: block;

				p {
					// font-size: 15px; 
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&-name {
				max-width: none;
				width: 100%;
				padding-right: 60px;
				margin: 0 0 10px;
			}
		}
	}
}

.feedback_card.in-modal {
	opacity: 1;
	border: none;
	padding: 0;
	margin: 0;
	
	.feedback_card {
		&-wrapper {
			padding: 0;
			border: 0;
		}

		&-text {
			margin: 0 0 1.7rem;

			&--subject {
				p {
					font-weight: 400;
				}
			}
			
			p {
				height: auto;
				line-height: 24px;
			}
		}

		&-name {
			margin: 0 0 1.7rem;
			font-size: 18px;
			line-height: 22px;
		}

		&-subtitle {
			margin-bottom: 11px;
		}

		&-rate {
			margin: 0 0 2.9rem;

			&--text {
				font-size: 16px;
			}
		}

		&-date {
			padding-top: 15px;
		}
	}
}	

.personal_content {
	margin-bottom: 2rem;
}

.tab-content {
	display: none;
}

.tab-content.active {
	display: block;
}

.leave_feedback {
	&-whom {
		margin: -20px 0 25px;

		&--title {
			margin: 0 0 5px;
			font-size: 18px;
			line-height: 22px;
			font-weight: bold;
		}

		&--text {
			font-size: 16px;
			line-height: 19px;
			word-break: break-word;
		}
	}
}

.users_autocomplete {
	margin: 0 0 24px;
	position: relative;

	&--input_group {
		border-bottom: 1px solid #000;
	}

	label {
		position: absolute;
		bottom: 100%;
		left: 0;
	}

	&-list {
		position:absolute;
		left: 0;
		top: 100%;
		width: 100%;
		max-height: 300px;
		overflow-y: auto;
		margin: 0;
		border: 1px solid grey;
		background: $white;
		z-index: 2;
		
		li {
			padding: 7px 10px;
			background: $white;
			transition: all 200ms ease;
			cursor: pointer;

			&:hover {
				background: lightgrey;
			}
		}
	}
	
	.input-field {
		margin: 0 0 0 10px;
		flex: 1;

		input {
			height: 30px;
			margin: 0;
			border-bottom-width: 1px;
			border: none!important;
		}
	}
	
	&-values {
		min-height: 45px;
		padding: 10px 0 5px;
		margin: 0 0 24px;
		border-bottom: 1px solid #000;
		width: 100%;
		margin: 0 0 0 -10px;
		border: none;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;

		&--value {
			display: inline-block;
			padding: 0 0 5px 10px;
			white-space: nowrap;
			font-size: 16px;
			line-height: 24px;

			i {
				margin-left: 5px;
				color: $ocean;
				vertical-align: top;
				cursor: pointer;
			}
		}
	}
	.helper-text.invalid{
		margin-top: 5px;
		display: block;
		color: #f44336;
		font-size: 14px;
	}
}

.feedbacks_list,
.requests_list {

	.feedback_card-text {
		overflow: hidden;
		padding-right: 5px;

		p {
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

@media (max-width: 768px) {
	.main_page {
		// .feedbacks_list,
		.requests_list {
			.row {
				.col+.col+.col {
					display: none;
				}
			}
		}
	}
}



.login_page {
	margin: 0 auto;
	min-height: calc(100vh - 160px);
	display: flex;
	align-items: center;
	overflow: hidden;


	h1 {
		font-size: 36px;
		line-height: 43px;
	}

	p {
		margin: 0 0 40px;
		line-height: 23px;
	}

	>.col {
		width: 100%;
	}

	.login-button {
		max-width: 360px;
	}
}

.login_content {
	position: relative;
}


.figure_dialog {
	position: absolute;
	top: -105px;
	left: 27px;
	width: 66px;
	height: 60px;
	background: url('../img/dialog_3.svg') center no-repeat;
	background-size: contain;

	&--2 {
		width: 151px;
		height: 137px;
		left: 103%;
		top: -20px;
		background-image: url('../img/dialog_1.svg');
	}
	&--3 {
		width: 95px;
		height: 87px;
		left: 88%;
		top: 110%;
		background-image: url('../img/dialog_2.svg');
	}
}

.manager {
	&-columns {
		display: flex;
	}

	&-users-list {
		padding-left: 20px;

		li {
			padding: 10px 0;
			border-bottom: 1px solid gray;

			&:last-child {
				border: 0;
			}
		}
	}

	&-col {
		&--list {
			max-width: 34%;
			width: 100%;
			padding-left: 20px;
		}

		&--content {
			padding-right: 20px;
			border-right: 1px solid #c1c1c1;
		}
	}
}

.json-data-block {
	padding: 15px;
	margin: 20px 0;
	height: 350px;
	min-height: 100px;
	overflow: auto;
	border: 1px solid #777;
	resize: vertical;
	background: #f1f1f1;
}

.file-field {
	max-width: 400px;

	.btn {
		max-width: 150px;
	}
}

.duplicated-row {
	display: flex;
	justify-content: space-between;

	.btn {
		width: auto;
		height: auto;
		padding: 0.6em 1.5em;
		line-height: 1.3;
	}
}

.filtered-users {
	list-style: none;
	padding: 0;
	margin: 0 0 20px;

	li {
		padding: 5px 12px;
		cursor: pointer;

		&:hover {
			background-color: #e3e3e3;
		}
	}
}

.filter-input-block {
	position: relative;

	.filtered-users {
		position: absolute;
		left: 0;
		top: 100%;
		z-index: 10;
		// display: none;
		max-height: 240px;
		overflow-y: auto;
		background-color: $white;
		border: 1px solid #ccc;
	}

	.filter-reset {
		position:absolute;
		right:0;
		top: 0;
		width: 44px;
		height: 44px;
		text-transform: uppercase;
		background: none;
		border: 0;
		font-size: 18px;
		cursor: pointer;

		&:hover {
			background-color: #ececec;
		}
	}
}
