$black: #000;
$white: #fff;
$gray: #D1D3CA;
$coral: #FF5959;
$ocean: #002FA7;
$moss: #B3FF60;
$soft-black: #171717;
$off-white: #F3F2EF;
$shade-gray: #4C4C49;
$ember: #510F1B;
$midnight: #12121A;
$forest: #0D241E;