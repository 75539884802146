// Soehne
@font-face {
  font-family: 'Soehne';
  src: url('../fonts/soehne-leicht.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Soehne';
  src: url('../fonts/soehne-leicht-kursiv.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Soehne';
  src: url('../fonts/soehne-buch.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Soehne';
  src: url('../fonts/soehne-halbfett.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//  Soehne woff2 file doesn't have multiple weights
// @font-face {
//   font-family: 'Soehne';
//   src: url('../fonts/soehne-leicht.woff2') format('woff2');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Soehne';
//   src: url('../fonts/soehne-leicht.woff2') format('woff2');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// ValtechNeue
@font-face {
  font-family: 'ValtechNeue';
  src: url('../fonts/FFF-ValtechNeue-Light.woff2') format('woff2'),
       url('../fonts/FFF-ValtechNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ValtechNeue';
  src: url('../fonts/FFF-ValtechNeue-Book.woff2') format('woff2'),
       url('../fonts/FFF-ValtechNeue-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}